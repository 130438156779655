import React from 'react';

import classNames from 'classnames';
import Image from '../Image';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import styles from './CardCtaSmall.module.scss';
import Icon from '../Icon';

const CardCtaSmall = ({
    title = '',
    text = '',
    modifier = '',
    link = {},
    image = {},
}) => {
    const handleClick = (title) => {
        window._mtm = window._mtm || [];
        window._mtm.push({
            ctaButtonName: title,
            event: 'interactionsCallToActionButton',
        });
    };

    return (
        <a
            href={link.href}
            target={link.target}
            onClick={() => handleClick(title)}
            className={classNames(styles.CardCtaSmall, {
                [styles[`CardCtaSmall--${modifier}`]]: modifier,
            })}>
            {image && image.url && (
                <LazyLoad once>
                    <div className={styles.CardCtaSmall__ImageContainer}>
                        <Image
                            {...{ image }}
                            sizes={'(min-width: 1680px) 640px'}
                        />
                    </div>
                </LazyLoad>
            )}
            <div className={styles.CardCtaSmall__Content}>
                <h3 className={styles.CardCtaSmall__Title}>
                    {title}
                    <span className={styles.CardCtaSmall__TitleArrow}>
                        <Icon
                            type="arrow"
                            dimensions={{ width: '21px', height: '15px' }}
                        />
                    </span>
                </h3>
                <div
                    className={styles.CardCtaSmall__Text}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </div>
            <span className={styles.CardCtaSmall__Arrow}>
                <Icon
                    type="arrow"
                    dimensions={{ width: '21px', height: '15px' }}
                />
            </span>
        </a>
    );
};

CardCtaSmall.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
        focal: PropTypes.shape({
            x: PropTypes.string,
            y: PropTypes.string,
        }),
        altText: PropTypes.string,
    }),
    modifier: PropTypes.string,
};

export default CardCtaSmall;
