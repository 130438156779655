import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ucFirst } from '../../utils/caseconverters';
import styles from './CardCtaSmallList.module.scss';
import CardCtaSmall from '../CardCtaSmall';

const CardCtaSmallList = ({ title = '', theme = '', items = [] }) => {
    if (!items.length) {
        return null;
    }

    const classes = classNames(
        styles['CardCtaSmallList'],
        styles['CardCtaSmallList--' + ucFirst(theme)]
    );

    return (
        <div className={classes}>
            <div className={styles.CardCtaSmallList__Container}>
                <div
                    className={styles.CardCtaSmallList__Title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <ul className={styles.CardCtaSmallList__List}>
                    {items.map((item, index) => (
                        <li
                            key={index}
                            className={styles.CardCtaSmallList__Item}>
                            <CardCtaSmall {...item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

CardCtaSmallList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            text: PropTypes.string,
            link: PropTypes.shape({
                title: PropTypes.string,
                href: PropTypes.string,
                target: PropTypes.string,
            }),
            image: PropTypes.shape({
                renditions: PropTypes.shape({
                    mobile: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                    mobile2x: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                    desktop: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                    desktop2x: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                }),
            }),
        })
    ),
};

export default CardCtaSmallList;
